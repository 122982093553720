<template>
  <div class="ion-page" main>
    <ion-loading-controller></ion-loading-controller>
    <ion-header>
      <ion-toolbar :color="colors('secondary')">
        <ion-buttons slot="end">
          <ion-button @click="obtener">
            <ion-icon name="refresh" :color="colors('light')"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Asunto</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="openEnd">
            <!--ion-icon name="refresh" :color="colors('light')"></ion-icon-->
            <i class="icon ion-ios-menu"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-progress-bar type="indeterminate" v-if="cargando"></ion-progress-bar>
    </ion-header>
    <ion-content class="content">
      <ion-row>
        <ion-col
          no-padding
          size-xs="12"
          size-sm="12"
          :size-md="esMovil ? 12 : 8"
          :size-lg="esMovil ? 12 : 9"
        >
          <ion-item lines="none" v-if="yo.rol == 'administrador'">
            <ion-segment
              :value="modo"
              v-on:ionChange="modo = $event.detail.value"
            >
              <ion-segment-button :value="'general'">
                General
              </ion-segment-button>
              <ion-segment-button :value="'cobranza'">
                Cobranza
              </ion-segment-button>
              <ion-segment-button
                :value="'simulacioncobranza'"
                v-if="esquemaCobranza.length > 0"
              >
                Simulación cobranza
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-list v-if="modo == 'general'">
            <SelectorCliente
              :idCliente="asunto.idCliente"
              v-if="asunto.fecha"
            />
            <ion-item v-if="yo.rol == 'administrador' && !modoProduccion">
              <ion-label>ID</ion-label>
              <ion-input
                class="ion-text-right"
                type="text"
                placeholder="Requerido"
                :value="asunto._id"
                v-on:ionChange="asunto._id = $event.detail.value"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label> Tipo de asunto </ion-label>
              <ion-input
                class="ion-text-right"
                type="text"
                placeholder="Requerido"
                :value="asunto.nombre"
                v-on:ionChange="asunto.nombre = $event.detail.value"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label> Folio (interno) </ion-label>
              <ion-input
                class="ion-text-right"
                type="text"
                placeholder="Requerido"
                :value="asunto.folio"
                v-on:ionChange="asunto.folio = $event.detail.value"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label> Área de práctica </ion-label>
              <ion-select
                class="ion-text-right"
                :value="asunto.area"
                v-on:ionChange="asunto.area = $event.detail.value"
              >
                <ion-select-option v-for="a in areas" :key="a.nombre">{{
                  a.nombre
                }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Actor </ion-label>
              <ion-textarea
                :value="asunto.actor"
                v-on:ionChange="asunto.actor = $event.detail.value"
              ></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Demandado </ion-label>
              <ion-textarea
                :value="asunto.demandado"
                v-on:ionChange="asunto.demandado = $event.detail.value"
              ></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-label position=""> Numero de expediente </ion-label>
              <ion-input
                class="ion-text-right"
                :value="asunto.expediente"
                v-on:ionChange="asunto.expediente = $event.detail.value"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position=""> Juzgado </ion-label>
              <ion-input
                class="ion-text-right"
                :value="asunto.juzgado"
                v-on:ionChange="asunto.juzgado = $event.detail.value"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Estado procesal </ion-label>
              <ion-textarea
                :value="asunto.status"
                v-on:ionChange="asunto.status = $event.detail.value"
              ></ion-textarea>
            </ion-item>
            <FormaPagoHandler
              :idAsunto="asunto._id"
              :formaPago2="asunto.formaPago"
              :idCliente="asunto.idCliente"
              v-if="asuntoCobrable && asunto._id && empresa.usarModuloTarifas"
            />
            <ion-item
              v-if="yo.rol == 'administrador' || permiso('editarAsuntos')"
            >
              <ion-label> Terminado </ion-label>
              <ion-input
                type="date"
                class="ion-text-right"
                :value="asunto.fechaTerminacion"
                v-if="asunto.fechaTerminacion"
                readonly
              ></ion-input>
              <ion-checkbox
                slot="end"
                :checked="asunto.terminado == 1"
                v-on:ionChange="
                  toggleTerminarAsunto($event.detail.checked == true)
                "
                v-on:ionChangee="
                  asunto.terminado = $event.detail.checked == true ? 1 : 0
                "
              ></ion-checkbox>
            </ion-item>
            <ion-item v-if="asunto.idAutor != yo._id && autor">
              Creado por {{ autor.nombre }}
            </ion-item>
            <ion-row>
              <ion-col v-if="permiso('EditarAsuntos') || !asunto._id">
                <ion-button
                  expand="block"
                  :disabled="botonGuardar != 'Guardar' || cargando"
                  @click="guardar"
                >
                  <i class="icon ion-ios-save"></i>&nbsp;
                  {{ botonGuardar }}
                </ion-button>
              </ion-col>
              <ion-col
                v-if="
                  permiso('CosteoActividades') &&
                  (asunto.histogramaCobranza || []).length > 0
                "
              >
                <ion-button
                  expand="block"
                  :disabled="botonGuardar != 'Guardar' || cargando"
                  :href="'#/acuerdos/' + asunto.histogramaCobranza[0]._id"
                  color="tertiary"
                >
                  <i class="icon ion-ios-card"></i>&nbsp; Cobranza
                </ion-button>
              </ion-col>
              <ion-col
                v-if="asunto._id && permiso('ReporteMisActividades')"
                size="4"
              >
                <ion-button
                  expand="block"
                  :color="colors('secondary')"
                  :href="'#/facturable/asunto/' + asunto._id"
                >
                  <i class="icon ion-ios-document"></i>&nbsp; PDF
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-list>
          <div v-if="modo == 'simulacioncobranza'">
            <ion-list v-if="esquemaCobranza.length > 0">
              <ion-item v-for="e in esquemaCobranza" :key="e.fecha">
                <ion-label>
                  <h2>{{ e.descripcion }}</h2>
                  <p>{{ e.tipo }} {{ (e.autor || {}).nombre }}</p>
                  <p>
                    {{ (e.minutosCliente || e.minutos) / 60 }}h,
                    {{ e.fechaProgramada | moment("MMM DD, YYYY") }}
                  </p>
                </ion-label>
                <ion-note slot="end">{{ e.importe | currency }}</ion-note>
              </ion-item>
            </ion-list>
          </div>
          <div v-if="modo == 'cobranza'">
            <ion-item lines="none">
              <ion-label text-wrap>
                <h2>Esquema de cobranza actual</h2>
                <p v-if="(asunto.histogramaCobranza || []).length == 0">
                  No se reportarán a clientes los asuntos que no tengan esquema
                  de cobranza
                </p>
              </ion-label>
              <ion-button
                slot="end"
                v-if="
                  yo.rol == 'administrador' &&
                  (asunto.histogramaCobranza || []).length != 0
                "
                @click="simularCobranza"
                fill="outline"
                >Simular cobranza</ion-button
              >
              <ion-button
                slot="end"
                @click="agregarEsquemaCobranza"
                fill="clear"
              >
                <i class="icon ion-ios-add-circle"></i>
              </ion-button>
            </ion-item>
            <ion-list
              v-if="
                asunto.histogramaCobranza &&
                asunto.histogramaCobranza.length > 0
              "
            >
              <ion-card
                v-for="(h, indice) in asunto.histogramaCobranza"
                :key="indice"
              >
                <ion-row>
                  <ion-col class="ion-no-padding">
                    <ion-item>
                      <ion-label position="floating">Fecha inicio</ion-label>
                      <ion-input
                        :value="h.desdeFecha"
                        v-on:ionChange="
                          h.desdeFecha = $event.detail.value;
                          comprobarCobranzas();
                        "
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col class="ion-no-padding">
                    <ion-item>
                      <ion-label position="floating">Fecha fin</ion-label>
                      <ion-input
                        :value="h.hastaFecha"
                        v-on:ionChange="
                          h.hastaFecha = $event.detail.value;
                          comprobarCobranzas();
                        "
                        type="date"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-item>
                  <ion-label>Tipo de cobranza</ion-label>
                  <ion-select
                    :value="h.tipo"
                    v-on:ionChange="
                      h.tipo = $event.detail.value;
                      $forceUpdate();
                    "
                  >
                    <ion-select-option
                      v-for="i in [
                        'iguala',
                        'asunto',
                        'hora',
                        'gratuito',
                        'probono',
                      ]"
                      :key="i"
                      :value="i"
                      >{{ i }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
                <ion-item v-if="h.tipo == 'iguala'">
                  <ion-label text-wrap position="floating"
                    >Valor de la iguala (devengado último mes:
                    {{ calcularDevengadoIguala() | currency }})</ion-label
                  >
                  <ion-input
                    :value="h.monto"
                    v-on:ionChange="h.monto = parseFloat($event.detail.value)"
                    type="number"
                  ></ion-input>
                </ion-item>
                <ion-item v-if="h.tipo == 'asunto'">
                  <ion-label text-wrap position="floating"
                    >Valor del asunto (devengado:
                    {{ calcularDevengadoAsunto() | currency }})</ion-label
                  >
                  <ion-input
                    :value="h.monto"
                    v-on:ionChange="h.monto = parseFloat($event.detail.value)"
                    type="number"
                  ></ion-input>
                </ion-item>
                <ion-list v-if="h.tipo == 'hora'">
                  <ion-item v-for="(b, a) in empresa.tarifas" :key="a">
                    <ion-label position="" text-wrap class="ion-text-capitalize"
                      >Tarifa {{ a }}</ion-label
                    >
                    <ion-input
                      class="ion-text-right"
                      color="primary"
                      type="number"
                      :value="h.horas[a]"
                      v-on:ionChange="
                        h.horas[a] = parseFloat($event.detail.value)
                      "
                    />
                  </ion-item>
                </ion-list>
              </ion-card>
            </ion-list>
            <ion-item v-else lines="none">
              <ion-label color="danger" class="ion-text-center">
                <h2>No hay esquema de cobranza configurado</h2>
              </ion-label>
            </ion-item>
          </div>
        </ion-col>
        <ion-col
          v-if="!esMovil && asunto._id && qrlink != ''"
          size-xs="12"
          size-sm="12"
          size-md="4"
          size-lg="3"
        >
          <ion-img :src="qrlink"></ion-img>
          <ion-row>
            <ion-col>
              <ion-button
                expand="block"
                size="small"
                @click="imprimirEtiqueta"
                color="medium"
                fill="clear"
              >
                <i class="icon icon-sm ion-ios-print"></i>&nbsp;Etiqueta
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-footer v-if="error != ''">
      <ion-title color="danger">{{ error }}</ion-title>
    </ion-footer>
  </div>
</template>

<script>
import Api from "@/components/Api";
import SelectorCliente from "@/components/SelectorCliente";
import FormaPagoHandler from "@/components/FormaPagoHandler";
import CobranzaModel from "@/models/Cobranza";
import axios from "axios";
var QRCode = require("qrcode");
export default {
  name: "Asunto",
  props: {
    id: {
      type: String,
      default: "",
    },
    idCliente: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cargando: false,
      modo: "general",
      modoProduccion: Api.modoProduccion,
      yo: Api.obtenerLocal("elite.yo") || {},
      //empresa: Api.obtenerLocal('elite.empresa') || {},
      empresa: {},
      esMovil: Api.esMovil(),
      areas: [],
      asunto: {
        fecha: new Date().getTime(),
        acuerdos: [],
        histogramaCobranza: [],
      },
      asuntoCobrable: false,
      autor: {},
      actividades: [],
      abogados: {},
      actividad: {},
      botonGuardar: "Guardar",
      mostrarFormActividad: false,
      mostrandoActividadesAntiguas: false,
      strMoment: "MMM DD",
      idComprobarAsuntoCobrable: null,
      qrlink: "",
      error: "",
      esquemaCobranza: [],
    };
  },
  components: {
    SelectorCliente,
    FormaPagoHandler,
  },
  methods: {
    openEnd() {
      Api.$emit("open-menu");
    },
    pos() {
      this.$router.push("pos");
    },
    async imprimirEtiqueta() {
      alert("Por implementar", null, "warning");
      //window.open('./etiqueta zpl.pdf', '_blank')
      let ipImpresora = this.empresa.ipImpresora || "ZD420ELITE";
      let zpl = this.empresa.plantillaQR || "";
      var url = "http://" + ipImpresora + "/pstprnt";
      var method = "POST";
      var esAsync = true;
      // Configurar las cadenas
      // empresa.nombre,cliente.nombre,asunto.actor,asunto.demandado,
      // asunto.juzgado,asunto.expediente,asunto.estadoprocesal,asunto.fechanatural
      zpl = zpl.replace("XXEMPRESA.NOMBREXX", this.empresa.nombre || "");

      let cliente =
        typeof this.asunto.idCliente != "string"
          ? (await Api.get("cliente", this.asunto.idCliente[0])) || {}
          : (await Api.get("cliente", this.asunto.idCliente)) || {};
      zpl = zpl.replace("XXCLIENTE.NOMBREXX", cliente.nombre || "");
      zpl = zpl.replace("XXASUNTO.NOMBREXX", this.asunto.nombre || "");
      zpl = zpl.replace("XXASUNTO.FOLIOXX", this.asunto.folio || "");
      zpl = zpl.replace("XXASUNTO.ACTORXX", this.asunto.actor || "");
      zpl = zpl.replace("XXASUNTO.DEMANDADOXX", this.asunto.demandado || "");
      zpl = zpl.replace("XXASUNTO.JUZGADOXX", this.asunto.juzgado || "");
      zpl = zpl.replace("XXASUNTO.EXPEDIENTEXX", this.asunto.expediente || "");
      zpl = zpl.replace("XXASUNTO.ESTADOPROCESALXX", this.asunto.status || "");
      zpl = zpl.replace(
        "XXASUNTO.FECHANATURALXX",
        this.$options.filters.moment(this.asunto.fecha, "DD MMMM, YYYY") || ""
      );
      zpl = zpl.replace("XXASUNTO.QRLINKXX", window.location.href);
      zpl = zpl
        .replace(/Á/g, "A")
        .replace(/É/g, "E")
        .replace(/Í/g, "I")
        .replace(/Ó/g, "O")
        .replace(/Ú/g, "U")
        .replace(/á/g, "a")
        .replace(/é/g, "i")
        .replace(/í/g, "e")
        .replace(/ó/g, "o")
        .replace(/ú/g, "u");
      //zpl = zpl.replace('XXASUNTO.QRLINKXX', 'https://app.elite-abogados.mx/#/asunto/' + this.asunto._id)
      console.log("zpl?", zpl);
      // Mandar el comando
      if (Api.modoProduccion || true == confirm("Imprimir?"))
        try {
          let u = await axios({
            method,
            url,
            headers: {
              "Content-Type": "text/plain",
              Accept: "*/*",
            },
            data: zpl,
          });
          console.log("request?", u);
          alert("Comando enviado");
        } catch (e) {
          console.error("EPRINT", e);
          alert("Error de impresión");
        } //*/
    },
    async obtener() {
      this.cargando = true;
      this.mostrarFormActividad = false;
      console.log("Obteniendo", this.id);
      if (this.id == 0)
        this.asunto = {
          fecha: new Date(),
          idAutor: this.yo._id,
          idCliente: this.idCliente || "",
          formaPago: "horas",
          folio: await this.obtenerFolio(),
        };
      else {
        this.asunto = await Api.get("asunto", this.id);
        this.calcularQR();
      }
      this.asunto.fecha = this.$options.filters.moment(
        this.asunto.fecha,
        "YYYY-MM-DD"
      );
      this.comprobarAsuntoCobrable();
      console.log("Asunto", this.asunto.fecha, this.asunto);
      if (this.asunto.idAutor != this.yo._id)
        this.autor = await Api.get("usuario", this.asunto.idAutor);
      else this.autor = JSON.parse(JSON.stringify(this.yo));
      //
      let filtros = [
        "idAsunto,eq," + this.asunto._id,
        "fechaProgramada,gte," +
          (new Date().getTime() - 1000 * 60 * 60 * 24 * 30).toString(),
      ];
      let actividades = await Api.find("actividad", filtros);
      for (let a in actividades) {
        if (a.idAbogado) actividades[a].abogado = {};
        let idAbogado = actividades[a].idAbogado;
        console.log("Comprobando", idAbogado, this.abogados[idAbogado]);
        try {
          if (this.abogados[idAbogado] && this.abogados[idAbogado]._id) {
            actividades[a].abogado = this.abogados[idAbogado];
            console.log("Abogado recuperado", actividades[a].abogado.nombre);
          } else {
            actividades[a].abogado = await Api.get(
              "usuario",
              actividades[a].idAbogado
            );
            console.log("Abogado descargado", actividades[a].abogado);
            this.abogados[idAbogado] = JSON.parse(
              JSON.stringify(actividades[a].abogado)
            );
          }
        } catch (e) {
          console.error("No se pudo recuperar abogado", idAbogado);
        }
      }
      // Actividades
      this.actividades = actividades.sort((a, b) => {
        return a.fechaProgramada < b.fechaProgramada ? 1 : -1;
      });
      console.log("Actividades", this.actividades, filtros);
      this.mostrandoActividadesAntiguas = false;

      this.cargando = false;
      this.$forceUpdate();
    },
    async obtenerFolio() {
      let folio = 1;
      try {
        let ultimoFolio = await Api.find("asunto", [], {
          sort: ["folio,-1"],
          limit: 5,
        });
        console.log(
          "folios?",
          ultimoFolio.map((u) => u.folio)
        );
        if (!isNaN(parseInt(ultimoFolio[0].folio)))
          folio = parseInt(ultimoFolio[0].folio) + 1;
        else throw new Error("Ultimo folio no era número");
      } catch (e) {
        console.error(e);
      }
      return folio;
    },
    toggleTerminarAsunto(val) {
      console.log("toggleTerminarAsunto?", val);
      if (val == true) {
        this.asunto.termminado = 1;
        this.asunto.fechaTerminacion = this.$options.filters.moment(
          new Date(),
          "YYYY-MM-DD"
        );
      } else {
        if (this.asunto.terminado) delete this.asunto.terminado;
        if (this.asunto.fechaTerminacion) delete this.asunto.fechaTerminacion;
      }
      console.log(
        "toggleTerminarAsunto",
        this.asunto.terminado,
        this.asunto.fechaTerminacion
      );
      this.$forceUpdate();
    },
    configurarCliente(cl) {
      console.log("$on clienteSeleccionado", cl);
      this.asunto.idCliente = cl;
      console.log("Cliente configurado", this.asunto);
    },
    async cargarMasActividades() {
      let actividades = await Api.find("actividad", [
        "idAsunto,eq," + this.asunto._id,
        "fechaProgramada,lt," +
          (new Date().getTime() - 1000 * 60 * 60 * 24 * 30).toString(),
      ]);
      for (let a in actividades) {
        if (a.idAbogado) actividades[a].abogado = {};
        let idAbogado = actividades[a].idAbogado;
        console.log("Comprobando", idAbogado, this.abogados[idAbogado]);
        try {
          if (this.abogados[idAbogado] && this.abogados[idAbogado]._id) {
            actividades[a].abogado = this.abogados[idAbogado];
            console.log("Abogado recuperado", actividades[a].abogado.nombre);
          } else {
            actividades[a].abogado = await Api.get(
              "usuario",
              actividades[a].idAbogado
            );
            console.log("Abogado descargado", actividades[a].abogado);
            this.abogados[idAbogado] = JSON.parse(
              JSON.stringify(actividades[a].abogado)
            );
          }
        } catch (e) {
          console.error("No se pudo recuperar abogado", idAbogado);
        }
      }
      actividades = actividades.sort((a, b) => {
        return a.fechaProgramada < b.fechaProgramada ? 1 : -1;
      });
      this.actividades = this.actividades.concat(actividades);
      this.mostrandoActividadesAntiguas = true;
      console.log("Actividades", this.actividades);
    },
    async guardar() {
      this.botonGuardar = "Guardando...";
      let permitirGuardar = true;
      if (!this.asunto.nombre || this.asunto.nombre.length < 1)
        permitirGuardar = false;
      if (permitirGuardar) {
        this.asunto.guardadoManualmente = new Date().getTime();
        console.log("Guardando", this.asunto.fecha, this.asunto);
        //const asuntoNoExistia = !this.asunto._id;
        this.asunto.fecha =
          new Date(this.asunto.fecha).getTime() + 1000 * 60 * 60 * 6;
        // Quitar acuerdos duplicados
        let histogramaPorIndices = {};
        for (let h of this.asunto.histogramaCobranza || [])
          histogramaPorIndices[h.idAcuerdocobranza] = h;
        console.log("histogramaPorIndices?", histogramaPorIndices);
        this.asunto.histogramaCobranza = [];
        for (let i in histogramaPorIndices)
          this.asunto.histogramaCobranza.push(histogramaPorIndices[i]);
        //
        let asunto = await Api.save("asunto", this.asunto);
        if (!this.asunto._id && asunto._id) this.asunto._id = asunto._id;
        console.log("Asunto?", asunto._id || asunto);
        alert("Guardado");
        //if (asuntoNoExistia) {
        this.$router.push("/asunto/" + this.asunto._id);
        await Api.wait();
        window.location.reload();
        //}
        this.calcularQR();
        this.botonGuardar = "Guardar";
        this.asunto = asunto;
        this.obtener();
        setTimeout(this.obtener, 1000);
      } else alert("Faltan datos");
      let vm = this;
      setTimeout(function () {
        vm.botonGuardar = "Guardar";
        vm.$forceUpdate();
      }, 1500);
    },
    verActividad(act) {
      this.mostrarFormActividad = false;
      let vm = this;
      setTimeout((_) => {
        if (!(act && act.idAsunto))
          act = {
            idAutor: vm.yo._id,
            idAsunto: vm.asunto._id,
          };
        vm.actividad = act;
        vm.mostrarFormActividad = true;
      }, 200);
    },
    eliminarActividad(a) {
      let vm = this;
      if (confirm("Desea eliminar esta actividad?"))
        Api.delete("actividad", a._id).then((_) => {
          setTimeout(async (_) => {
            vm.obtener();
            let lista = document.querySelector("#lista-asuntos");
            await lista.componentOnReady();
            lista.closeSlidingItems();
          }, 1000);
        });
    },
    configurarFormaPago(val) {
      this.asunto.formaPago = val;
      console.log("Forma de pago cambiada", this.asunto.formaPago);
      this.$forceUpdate();
    },
    async comprobarAsuntoCobrable() {
      let camposRequeridos = ["idCliente", "nombre", "area"];
      let asuntoCobrable = true;
      for (let campo of camposRequeridos)
        if (!this.asunto[campo] || this.asunto[campo] == "")
          asuntoCobrable = false;
      console.log("Asunto es cobrable?", asuntoCobrable);
      this.asuntoCobrable = asuntoCobrable;
      if (this.asuntoCobrable && !this.asunto._id) {
        // Guardar el asunto para que pueda haber un ID asociado al acuerdo
        console.log("Guardando asunto");
        this.asunto.fecha = new Date().getTime() + 1000 * 60 * 60 * 6;
        let asunto = await Api.save("asunto", this.asunto);
        this.asunto._id = asunto._id;
        console.log("Guardado. Acuerdo asociable.", asunto);
        this.$forceUpdate();
      }
    },
    calcularQR() {
      let vm = this;
      try {
        QRCode.toDataURL(window.location.href, function (err, url) {
          console.log("qrcode", window.location.href, url);
          vm.qrlink = url;
          vm.$forceUpdate();
        });
      } catch (e) {
        alert("No se pudo obtener QR");
        this.qrlink = "";
      }
    },
    agregarEsquemaCobranza() {
      let esquemaCobranza = {
        idAutor: this.yo._id,
        fechaAgregado: new Date().getTime(),
        desdeFecha: this.$options.filters.moment(
          this.asunto.fecha,
          "YYYY-MM-DD"
        ),
        hastaFecha: this.$options.filters.moment(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10,
          "YYYY-MM-DD"
        ),
        tipo: "hora", //'hora','iguala','asunto'
        horas: {},
      };
      for (let i in this.empresa.tarifas || {})
        esquemaCobranza.horas[i] = this.empresa.tarifas[i];
      if (!this.asunto.histogramaCobranza) this.asunto.histogramaCobranza = [];
      this.asunto.histogramaCobranza.push(esquemaCobranza);
      console.log("agregarEsquemaCobranza?", this.asunto.histogramaCobranza);
      this.$forceUpdate();
    },
    calcularDevengadoAsunto() {
      return 0;
    },
    calcularDevengadoIguala() {
      return 0;
    },
    comprobarCobranzas() {
      console.log("Comprobando cobranzas");
      alert("Por implementar comprobarCobranzas()");
    },
    async simularCobranza() {
      alert("Simulando");
      this.cargando = true;
      this.esquemaCobranza = (
        await CobranzaModel.calcularParaAsunto(this.asunto)
      ).actividades;
      await Api.wait(200);
      this.modo = "simulacioncobranza";
      console.log("simularCobranza?", this.esquemaCobranza);
      this.cargando = false;
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.empresa = (await Api.get("empresa"))[0] || {};
    console.log("empresa?", this.empresa.nombre, this.empresa.ipImpresora);
    let vm = this;
    setTimeout(this.obtener, 500);
    this.areas = await Api.get("area");
    Api.$on("ActividadGuardada", () => {
      vm.obtener();
      vm.actividad = {};
      setTimeout(vm.obtener, 500);
    });
    Api.$on("clienteSeleccionado", this.configurarCliente);
    Api.$on("formaPagoCambiada", this.configurarFormaPago);
    this.idComprobarAsuntoCobrable = setInterval(
      this.comprobarAsuntoCobrable,
      2500
    );
  },
  beforeDestroy() {
    Api.$off("ActividadGuardada");
    Api.$off("clienteSeleccionado");
    Api.$off("formaPagoCambiada");
    /*
      if (!this.asunto.guardadoManualmente &&
        (this.asunto._id && this.asunto._id.length > 0)) {
        // Se generó un asunto pero no se guardó expl´citamente y por tanto hay que borrarlo
        console.warn("Borrando asunto sin guardar manualmente", this.asunto._id)
        Api.delete('asunto', this.asunto._id)
      }*/
    clearInterval(this.idComprobarAsuntoCobrable);
  },
};
</script>
